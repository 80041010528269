import { Entries } from '../types/types.utils';
// eslint-disable-next-line import/prefer-default-export
export const objectEntries = <Object_ extends object>(object: Object_) => {
  return Object.entries(object) as Entries<Object_>;
};

export const ROOT_ELEMENT_CSS_CLASS = '.dvinci-job-widget';

export const isStellenliste = () =>
  document.querySelector<HTMLDivElement>(ROOT_ELEMENT_CSS_CLASS)?.dataset.stellenliste === 'true';

export const getStellenlisteGlobalId = () =>
  document.querySelector<HTMLDivElement>(ROOT_ELEMENT_CSS_CLASS)?.dataset.stellenlisteGlobalId || undefined;

export const getAttributes = () => {
  const attributes: any = {};
  const djw = document.querySelector<HTMLDivElement>(ROOT_ELEMENT_CSS_CLASS);
  if (djw) {
    for (const attribute of djw.getAttributeNames()) {
      attributes[attribute] = djw.getAttribute(attribute);
    }
  }
  return attributes;
};

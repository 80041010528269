import React from 'react';
import { Trans } from '@lingui/macro';

const LoadingIndicator = () => (
  <div className="card-body" role="status">
    <div className="spinner-border">
      <span className="visually-hidden">
        <Trans>Loading...</Trans>
      </span>
    </div>
  </div>
);

export default LoadingIndicator;

import { Coords } from 'google-map-react';
import themeRegistry, { defaultTheme } from '../../../themes/theme-registry';
import { FilterDefinition, FilterDefinitions, ParsedFilters } from '../types/filters';
import ParsedConfig from '../types/parsed-config';
import { CardContent, CardContentConfig, FilterConfig, Filters, Sort } from '../../types/config';
import { CardContentDefinition, CardContentDefinitions, ParsedCardContent } from '../types/card-content';

const filterDefinitions: Filters<FilterDefinition> = {
  maps: {
    key: 'maps',
    uppercase: false,
  },
  keywords: {
    key: 'keyword',
    uppercase: false,
  },
  orgUnits: {
    key: 'orgunit',
    uppercase: true,
  },
  companies: {
    key: 'company',
    uppercase: true,
  },
  categories: {
    key: 'category',
    uppercase: true,
  },
  locations: {
    key: 'location',
    uppercase: true,
  },
  targetGroups: {
    key: 'targetgroup',
    uppercase: true,
  },
  languages: {
    key: 'language',
    uppercase: false,
  },
};

const cardContentDefinitions: CardContent<CardContentDefinition> = {
  orgUnit: {
    key: 'orgunit',
  },
  company: {
    key: 'company',
  },
  categories: {
    key: 'categories',
  },
  locations: {
    key: 'locations',
  },
  targetGroups: {
    key: 'targetgroups',
  },
  workingTimes: {
    key: 'workingtimes',
  },
  contractPeriod: {
    key: 'contractperiod',
  },
  earliestEntryDate: {
    key: 'earliestentrydate',
  },
};

const parseConfig = (element?: Element | null): ParsedConfig => {
  return {
    data: {
      url: element?.getAttribute('data-url') || undefined,
      language: element?.getAttribute('lang')?.toLowerCase() || undefined,
      fieldsSmall: parseBoolean(element?.getAttribute('data-fields-small') || undefined),
      maxCacheAge: element?.getAttribute('data-max-cache-age')?.toLowerCase() || undefined,
    },
    ui: {
      theme:
        themeRegistry.find(
          (it) => it === (element?.getAttribute('data-theme') || element?.getAttribute('data-skin')),
        ) ||
        // window.dvinciJobWidgetConfig?.ui.theme ||
        defaultTheme,
      jobsPerPage: parseNumber(element?.getAttribute('data-list-jobs-per-page') || undefined),
      showEmptyChoices: parseBoolean(element?.getAttribute('data-filter-show-empty-choices') || undefined),
      linkTarget: element?.getAttribute('data-link-target') || undefined,
      sort: parseSort(element),
      showLabels: parseBoolean(element?.getAttribute('data-show-labels') || undefined),
      showIcons: parseBoolean(element?.getAttribute('data-show-icons') || undefined),
      lazyLoading: parseBoolean(element?.getAttribute('data-lazy-loading') || undefined),
      flagAsNew: parseNumber(
        element?.getAttribute('data-flag-as-new') ||
          // window.dvinciJobWidgetConfig?.ui.flagAsNew ||
          5,
      ),
      flagAsNewEnabled: parseBoolean(element?.getAttribute('data-flag-as-new-enabled') || undefined),
    },
    cardContent: parseCardContent(cardContentDefinitions, element),
    styles: {
      brandColor: element?.getAttribute('data-style-brand-color') || undefined,
      brandContrastColor: element?.getAttribute('data-style-brand-contrast-color') || undefined,
      textColor: element?.getAttribute('data-style-text-color') || undefined,
      fontFamily: element?.getAttribute('data-style-font-family') || undefined,
      fontSize: element?.getAttribute('data-style-font-size') || undefined,
      fontWeight: element?.getAttribute('data-style-font-weight') || undefined,
      borderRadius: element?.getAttribute('data-style-border-radius') || undefined,
      labelStyle: parseStyle('label', element),
      headlineStyle: parseStyle('headline', element),
      inputStyle: parseBlockStyle('input', element),
      cardStyle: parseBlockStyle('card', element),
    },
    filters: parseFilters(filterDefinitions, element),
    api: {
      maps: {
        apiKey: element?.getAttribute('data-maps-api-key') || undefined,
        requireConsent: parseBoolean(element?.getAttribute('data-maps-require-consent') || undefined),
        privacyPolicyUrl: element?.getAttribute('data-maps-privacy-policy-url') || undefined,
        defaultCenter: parseCoords(element?.getAttribute('data-maps-default-center') || undefined),
      },
    },
  };
};

const parseCoords = (coords?: string): Coords | undefined => {
  if (typeof coords === 'undefined') return undefined;
  const parts = coords
    .split(',')
    .map((it) => Number(it.trim()))
    .filter((it) => Number.isFinite(it) && !Number.isNaN(it));
  if (parts.length !== 2) return undefined;
  return { lat: parts[1], lng: parts[0] };
};

const parseBoolean = (bool: string | undefined): boolean | undefined =>
  typeof bool !== 'undefined' ? !/^(false|0)$/i.test(String(bool)) && !!bool : undefined;

const parseNumber = (number: number | string | undefined): number | undefined =>
  typeof number !== 'undefined' ? Number(number) : undefined;

const parseSort = (element?: Element | null): Sort | undefined => {
  let sort: Sort | undefined;
  let sortDescending: boolean;

  switch (element?.getAttribute('data-sort-order')?.toLowerCase()) {
    case 'asc':
      sortDescending = false;
      break;
    default:
      sortDescending = true;
  }

  switch (element?.getAttribute('data-sort-by')?.toLowerCase()) {
    case 'date':
      sort = { by: 'date', order: sortDescending ? 'desc' : 'asc' };
      break;
    case 'title':
      sort = { by: 'title', order: sortDescending ? 'desc' : 'asc' };
      break;
    default:
  }

  return sort;
};

const parseStyle = (instance: string, element?: Element | null): Object => {
  return {
    color: element?.getAttribute(`data-style-${instance}-color`) || undefined,
    fontFamily: element?.getAttribute(`data-style-${instance}-font-family`) || undefined,
    fontSize: element?.getAttribute(`data-style-${instance}-font-size`) || undefined,
    fontWeight: element?.getAttribute(`data-style-${instance}-font-weight`) || undefined,
  };
};

const parseBlockStyle = (instance: string, element?: Element | null): Object => {
  return {
    background: element?.getAttribute(`data-style-${instance}-background`) || undefined,
    borderRadius: element?.getAttribute(`data-style-${instance}-border-radius`) || undefined,
  };
};

const parseFilters = (filters: FilterDefinitions, element?: Element | null): Filters<Partial<FilterConfig>> => {
  const filterConfigs: { [key: string]: Partial<FilterConfig> } = {};

  // eslint-disable-next-line no-restricted-syntax
  for (const [name, definition] of Object.entries(filters)) {
    filterConfigs[name] = {
      enabled: parseBoolean(element?.getAttribute(`data-filter-${definition.key}-enabled`) || undefined),
      hidden: parseBoolean(element?.getAttribute(`data-filter-${definition.key}-hidden`) || undefined),
      selected: parseList(
        definition.uppercase,
        parseSearch(definition.key) || element?.getAttribute(`data-filter-${definition.key}-selected`) || undefined,
      ),
      allowed: parseList(
        definition.uppercase,
        element?.getAttribute(`data-filter-${definition.key}-allowed`) || undefined,
      ),
      order: parseNumber(element?.getAttribute(`data-filter-${definition.key}-order`) || undefined),
      label: element?.getAttribute(`data-filter-${definition.key}-label`) || undefined,
    };
  }

  return filterConfigs as ParsedFilters;
};

const parseCardContent = (
  cardContent: CardContentDefinitions,
  element?: Element | null,
): CardContent<Partial<CardContentConfig>> => {
  const cardContentConfigs: { [key: string]: Partial<CardContentConfig> } = {};

  // eslint-disable-next-line no-restricted-syntax
  for (const [name, definition] of Object.entries(cardContent)) {
    cardContentConfigs[name] = {
      enabled: parseBoolean(element?.getAttribute(`data-card-content-${definition.key}-enabled`) || undefined),
      order: parseNumber(element?.getAttribute(`data-card-content-${definition.key}-order`) || undefined),
    };
  }

  return cardContentConfigs as ParsedCardContent;
};

const parseSearch = (key: string) => {
  const preselectedOption = document.location.search
    .replace(/(^\?)/, '')
    .split('&')
    .find((searchParameter) => {
      return searchParameter.split('=')[0] === key;
    })
    ?.split('=')[1];
  return decodeURI(preselectedOption || '');
};

const parseList = (toUpperCase: boolean, text?: string) => {
  if (typeof text === 'undefined') return text;
  return (toUpperCase ? text.toLocaleUpperCase() : text)
    .split(',')
    .map((it) => it.trim())
    .filter((it) => it.length > 0);
};

export default parseConfig;

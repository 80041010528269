const themeRegistry = [
  'default',
  'default-list',
  'default-list-with-button',
  'default-list-icons',
  'default-list-v2',
  'default-plain',
  'default-table',
  'default-table-v2',
  'default-cards',
  'default-cards-2col',
  'default-cards-3col',
  'default-cards-with-image',
  'default-cards-with-image-left',
  'default-large-cards',
  'default-large-cards-with-image',
  'default-large-cards-with-image-3col',
  'flughafen-muenchen',
  'complex',
  'dvinci-relaunch',
  'yesss',
  'linck',
  'adweko',
  'diakonie_hasenbergl',
  'sedo',
  'awado',
  'baader',
  'a_moksel_vion',
  'zott',
  'msg_life',
  'va-q-tec',
  'gustoso',
  'gustoso-ciaobella',
  'gustoso-cotidiano',
  'gustoso-cucina',
  'gustoso-burgermeister',
  'gustoso-gruppe',
  'gustoso-ottos-burger',
  'gustoso-ruffs-burger',
  'ciaobella',
  'cotidiano',
  'foodlounge',
  'ottosburger',
  'ruffsburger',
  'adstec',
  'advanced_unibyte',
  'al-ko',
  'albwerk',
  'generali',
  'arcona',
  'amkmotion',
  'dwkt',
  'froer',
  'bb',
  'binzel',
  'deutz_fahr',
  'leben_leben',
  'bowa',
  'internetx',
  'bav',
  'sauter',
  'sauter-list',
  'pandomus',
  'pandomus-list',
  'dmg',
  'bma',
  'sycor',
  'europart',
  'maerkischer-kreis',
  'gima',
  'reks',
  'coffee-fellows',
  'nagler',
  'bruecke',
  'jebsen-jessen',
  'nordkirche',
  'nordkirche-list',
  'tela',
  'm-juergensen',
  'lekker',
  'stadt_emden',
  'salus',
  'stiftung_liebenau',
  'stiftung_liebenau_blau',
  'sunzinet',
  'schott',
  'zdf_studio',
  'zdf_service',
  'jagenberg',
  'jagenberg_green',
  'jagenberg_orange',
  'jagenberg_grey',
  'desay',
  'hf',
  'stadtmission_halle',
  'ms',
  'lokomotion',
  'muehlberger',
  'zapf',
  'hamburger_energiewerke',
  'paragon_semvox',
  'paragon',
  'batzner',
  'ten-brinke',
  'ibc_solar',
  'o.phone',
  'isr',
  'richter',
  'diakonie-mosbach',
  'diakonie-mosbach-bbw',
  'wabe',
  'marmalade',
  'rnv',
  'ofa_bamberg',
  'hexagon_purus',
  'sopra-financial',
  'washtec_simple',
  'washtec_simple_at',
  'washtec',
  'schloemer',
  'doppelmayr',
  'maritim',
  'meinreal',
  'coffee_perfect',
  'avag',
  'klinikum-nuernberg',
  'awado-relaunch',
  'hal-privatbank',
  'hal-privatbank-intern',
  'elis',
  'bauking',
  'schuster',
  'lpg',
  'macs-hausfux',
  'macs-easylife',
  'msi',
  'msi-intranet',
  'msg_systems',
  'msg-services',
  'wupperverband',
  'danhauser',
  'sidion',
  'europart-relaunch',
  'wien-it',
  'weyland',
  'schrobenhausen',
  'continentale',
  'fishbull',
  'lieblang',
  'technogel',
  'nuways',
  'sit-nrw',
  'arri',
  'faist',
  'saga',
  'drk-niedersachsen',
  'drk-rostock',
  'felsenstein',
  'stolz',
  'mayer-cie-ausbildung',
  'mayer-cie',
  'nhd',
  'baywa',
  'gartencenter-augsburg',
  'hellweg',
  'mey',
  'hoftex',
  'hagebau-moelders',
  'romed',
  'encavis',
  'nwb',
  'raiffeisen',
  'bpc',
  'bugatti',
  'scharr',
  'gkg',
  'fokus-zukunft',
  'rieck',
  'bomag',
  'vag-freiburg',
  'beutlhauser',
  'service-bund',
  'altmann',
  'hima',
  'lhg',
  'vtours',
  'svt-endress',
  'hwg',
  'klinikum-fuerth',
  'nicko-cruises',
  'euler-group',
  'hephata',
  'bve',
  'stamag',
  'momox',
  'dkfz',
  'lindig',
  'qvest',
  'qibb',
  'jaques',
  'cp',
  'lkb',
  'butterback',
  'tectareal',
  'desitin',
  'desitin-top-jobs',
  'fette-compacting',
  'fette-compacting-ausbildung',
  'diak-stuttgart',
  'rewe-lieferservice',
  'hyve',
];

export const defaultTheme = 'default';

export default themeRegistry;

import { FallbackProps } from 'react-error-boundary';
import React from 'react';
import { t, Trans } from '@lingui/macro';
import * as styles from './error-fallback.scss';

const ErrorFallback = ({ error, resetErrorBoundary }: FallbackProps) => (
  <div className={styles.cardBody}>
    <div className={`${styles.alert} ${styles.alertDanger}`} role="alert">
      <strong>
        <Trans>An error occurred. Please try again later.</Trans>
      </strong>
      <pre>{error.message}</pre>

      <button
        type="button"
        className={`${styles.btn} ${styles.btnLight}`}
        aria-label={t`Retry now`}
        onClick={resetErrorBoundary}
      >
        <Trans>Retry now</Trans>
      </button>
    </div>
  </div>
);

export default ErrorFallback;

import 'react-app-polyfill/ie9';
// import 'react-app-polyfill/stable';
import React, { Suspense, useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { I18nProvider } from '@lingui/react';
import { createRoot } from 'react-dom/client';
import ErrorFallback from './components/error-fallback';
import LoadingIndicator from './components/loading-indicator';
import parseConfig from './config/parse/services/parse-config';
import i18n, { setLocale } from './i18n/services/i18n';
import ParsedConfig from './config/parse/types/parsed-config';
import { ROOT_ELEMENT_CSS_CLASS } from './utils/utils';

const element = document.querySelector(ROOT_ELEMENT_CSS_CLASS);
if (!element) {
  // eslint-disable-next-line no-console
  console.error('Missing Widget Placeholder (<div class="dvinci-job-widget" ...></div>)');
}
if (element && document.querySelectorAll(ROOT_ELEMENT_CSS_CLASS).length > 1) {
  // eslint-disable-next-line no-console
  console.error(
    'Multiple Widget Placeholder (<div class="dvinci-job-widget" ...></div>) on this page. This will lead to errors!',
  );
}
if (element && document.querySelectorAll('script[src*="dvinci-job-widget-2.0.0"]').length > 1) {
  // eslint-disable-next-line no-console
  console.error(
    'Multiple Widget Scripts (<script defer src="dvinci-job-widget-2.0.0.min.js"></script>) detected on this page. Widget will not load correctly!',
  );
}
if (element && document.querySelector('#main.job-publication-list #job-publication-table')) {
  const styles = document.createElement('style');
  styles.type = 'text/css';
  styles.textContent = '#main.job-publication-list #job-publication-table {display: none;}';
  document.querySelector('head')?.append(styles);
}

const config: ParsedConfig = window.dvinciJobWidgetConfig ? window.dvinciJobWidgetConfig : parseConfig(element);

const App = React.lazy(() => import('./components/app'));
const Theme = React.lazy(() => import(`./themes/${config.ui.theme}/theme`));

function Index() {
  useEffect(() => {
    setLocale(config.data.language).then();
  }, []);
  return (
    <React.StrictMode>
      <I18nProvider i18n={i18n}>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <Suspense fallback={<LoadingIndicator />}>
            <App parsedConfig={config} Theme={Theme} />
          </Suspense>
        </ErrorBoundary>
      </I18nProvider>
    </React.StrictMode>
  );
}

const loadCSS = async (url: string) => {
  // eslint-disable-next-line compat/compat
  return new Promise<void>((resolve) => {
    const link = document.createElement('link');
    link.type = 'text/css';
    link.rel = 'stylesheet';
    link.href = url;
    link.media = 'all';
    link.setAttribute('crossorigin', 'anonymous');
    link.addEventListener('load', () => {
      resolve();
    });

    const headScript = document.querySelector('script')!!;
    headScript.parentNode!!.insertBefore(link, headScript);
  });
};

const getScriptBase = (): { baseUrl: string; version: string } => {
  const script: HTMLScriptElement = document.querySelector('script[src*=dvinci-job-widget]')!!;
  const matches = script.src.match(/(^.*)dvinci-job-widget-(.*?)(?:\.min)?\.js/)!!;
  return {
    baseUrl: matches[1],
    version: matches[2],
  };
};

if (element) {
  const { baseUrl, version } = getScriptBase();
  loadCSS(`${baseUrl}dvinci-job-widget-${version}.css`).then(() => {
    createRoot(element).render(<Index />);
  });
}

import { i18n } from '@lingui/core';
import { I18n } from '../../config/types/config';
import { generateMessageId } from '@lingui/message-utils/generateMessageId';

export const defaultLocale = 'de';

function convertCustomMessages(messages: Record<string, Record<string, any>>) {
  for (const [key, value] of Object.entries(messages)) {
    let convertedMessages = {}
    for (let [messageId, message] of Object.entries(value)) {
      convertedMessages[generateMessageId(messageId)] = message
    }
    messages[key] = convertedMessages
  }
  return messages
}

export async function setLocale(locale?: string, overrides?: I18n) {
  const lc = locale || defaultLocale;
  const { messages } = await import(`../locales/${lc}.po`);
  i18n.load(lc, messages);
  if (overrides?.messages) {
    i18n.load(convertCustomMessages(overrides.messages));
  }
  i18n.activate(lc);
}

export { i18n as default } from '@lingui/core';
